.red {
	color: #ff0000;
}

.dataSelect {
	position: absolute;
	right: 0;
	top: 1vh;
	z-index: 99;
}
