.timeAndTab {
  position: relative;
  width: 200rem;

  .table {
    position: absolute;
    right: 0;
    // top: 0.15vh;
    // z-index: 99;
    /* background-color: #fff; */
    display: flex;
    flex-direction: row;
    color: #fff;

    div {
      padding: 0.4vh 30rem 0.4vh 30rem;
      display: flex;
      cursor: pointer;
      user-select: none;
      justify-content: center;
      align-items: center;
      background: url('../../../static/images/accessInfoBg.png') center no-repeat;
      background-size: 100% 100%;
      color: #fff;
      font-size: 12rem;
    }

    .active {
      background: url('../../../static/images/accessInfoActive.png') center no-repeat !important;
      background-size: 100% 100%;
      margin-right: -2px;
    }
  }

  .time-select{
    position: absolute;
    right: 0;
    margin-top:40rem;
  }
}
@primary-color: #0ad0d0;