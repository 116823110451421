.securityList {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	height: 100%;
	background-image: url('../../../../../static/images/securityBG.png');
	background-size: 40% 70%;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	padding-top: 20rem;
}

.securityList > .item {
	width: 50%;
	height: 50%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-left: 15%;
}


.securityList > .item > p {
	height: 16rem;
	font-size: 16rem;
	line-height: 16rem;
	color: #fff;
	padding-left: 18rem;
	margin-bottom: 6rem;
	background-image: url('../../../../../static/images/securityIcon1.png');
	background-size: contain;
	background-repeat: no-repeat;
}

.securityList > .item:nth-child(2) > p {
	background-image: url('../../../../../static/images/securityIcon2.png');
}

.securityList > .item:nth-child(3) > p {
	background-image: url('../../../../../static/images/securityIcon3.png');
}

.securityList > .item:nth-child(4) > p {
	background-image: url('../../../../../static/images/securityIcon4.png');
}

.securityList > .item .itemNumber {
	font-family: 'LcdD';
	font-size: 36rem;
	color: #06e7e9;
}

.securityList > .item:nth-child(1) .itemNumber {
	color: #e60012;
}

.securityList > .item:nth-child(2) .itemNumber {
	color: #e60012;
}

.securityList > .item .itemNumber .unit {
	color: #ffffff;
	font-size: 14rem;
}
