.device-check_box {
  position: absolute;
  bottom: 40rem;
  left: 500rem;

  // height: 400rem;
  width: 160rem;

  .ant-checkbox-wrapper+.ant-checkbox-wrapper {
    margin-left: 0;
  }

  .ant-checkbox-wrapper {
    display: block;
    color: #fff;
    font-size: 14rem;
    margin-top: 20rem;
  }

  .ant-checkbox-inner {
    background: rgba(160, 163, 164, 1);
    border: 1rem solid #d2d2d2;
  }

  .ant-checkbox::after {
    border: none;
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: #fff;
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    position: absolute;
    display: table;
    border: 2px solid #1b1b1b;
    border-top: 0;
    border-left: 0;
    transform: rotate(45deg) scale(1) translate(-50%, -50%);
    opacity: 1;
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    content: ' ';
  }

  // .ant-checkbox-inner::after{
  //     border: 2px solid #fff;
  // }
}

.marker_total1 {
  position: relative;
  width: 150rem;
  // height: 85rem;
  background: rgba(255, 255, 255, 0.4);

  border-radius: 4rem;
  left: 40rem;
  padding: 10rem;
  z-index: 99999999;

  &>div:nth-child(1) {
    margin-bottom: 10rem;
    position: relative;
    display: flex;
    // justify-content: center;
    align-items: center;

    span {
      margin-left: -10rem;
      margin-right: 4rem;
      display: flex;
      width: 5rem;
      height: 5rem;
      border-radius: 50%;
      background-color: #07C5F1;
    }
  }

  div {
    margin-left: 10rem;
    font-size: 12rem;
    font-weight: normal;
    color: #FFFFFF;
    line-height: 18rem;
  }


}
@primary-color: #0ad0d0;