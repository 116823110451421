.message-socket {
	display: inline-block;
	cursor: pointer;
}
.message-dll {
	position: relative;
	width: 23px;
	height: 27px;
	margin-right: 25px;

	.message-img {
		background-image: url('../../static/images/message.png');
		background-repeat: no-repeat;
		width: 23px;
		height: 27px;
		display: inline-block;
		position: absolute;
		top: -5px;
		left: -20px;
		z-index: 100;
	}
	.unread-count {
		position: absolute;
		right: -12px;
		top: -8px;
		width: 20px;
		height: 20px;
		background-color: #e60012;
		border-radius: 50%;
		text-align: center;
		line-height: 20px;
		color: #ffffff;
		font-size: 10px;
		z-index: 101;
	}
}

.notification-container {
	padding: 25px;
	background-color: #2d3548;
	box-shadow: 5px 6px 5px 0px rgba(20, 28, 48, 0.3);
	border-radius: 4px;
	width: 340px;
	height: 220px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	.notification-title {
		font-size: 14px;
		color: #ffffff;
		display: flex;
		align-items: center;
		justify-content: center;
		.notification-img {
			background: url('../../static/images/message-tips.png') no-repeat;
			background-size: 100% 100%;
			display: inline-block;
			width: 20px;
			height: 24px;
			margin-right: 14px;
		}
	}

	.notification-content {
		font-size: 14px;
		color: #ffffff;
		padding-top: 10px;
		padding-bottom: 35px;
		height: 100px;
		width: 100%;
		overflow: auto;
		word-break: break-all;
	}
	button {
		width: 100px;
		height: 40px;
	}
}

.ant-notification-notice.notification-style {
	padding: 0;
	background-color: transparent;
	box-shadow: none;
	.ant-notification-notice-close {
		right: 55px;
		top: 20px;
		color: #ffffff;
	}
}

.msg-audio {
	display: none;
}

@primary-color: #0ad0d0;