.cardTimeSelect {
	position: relative;

	.cardDataPick {
		position: absolute;
		width: 200rem;
		display: block;
		right: 0;
	}

	.ant-select-single.ant-select-sm {
		position: relative;

		&::before {
			content: '';
			position: absolute;
			z-index: 1;
			left: 0;
			top: 0;
			display: block;
			width: 5rem;
			height: 1px;
			background: linear-gradient(90deg, #0289f2 0%, #00baff 100%);
			z-index: 1;
		}

		&::after {
			content: '';
			position: absolute;
			display: block;
			right: 0;
			top: 0;
			width: 5rem;
			height: 1px;
			background: linear-gradient(90deg, #0289f2 0%, #00baff 100%);
		}

		.ant-select-selector {
			height: 22rem;
			font-size: 12rem;
			color: #ffffff;
			background: rgba(2, 28, 45, 0.5);
			border: 0;

			&::before {
				content: '';
				position: absolute;
				left: 0;
				bottom: 0;
				display: block;
				width: 5rem;
				height: 1px;
				background: linear-gradient(90deg, #0289f2 0%, #00baff 100%);
			}

			&::after {
				content: '';
				position: absolute;
				display: block;
				right: 0;
				bottom: 0;
				width: 5rem;
				height: 1px;
				visibility: visible;
				background: linear-gradient(90deg, #0289f2 0%, #00baff 100%);
			}
		}

		.ant-select-selection-search-input {
			height: 22rem;
		}

		.ant-select-selection-item {
			line-height: 22rem !important;
			text-align: center;
			padding-left: 18rem;
		}

		.ant-select-arrow {
			color: #ffffff;
			font-size: 8px;
			top: 60%;
		}
	}

	.ant-picker {
		position: relative;
		z-index: 99;
		font-size: 12rem;
		padding: 2rem 4rem;
		color: #fff;
		background: rgba(2, 28, 45, 0.5);

		.ant-picker-range-separator {
			padding: 0 4rem;
		}

		.ant-picker-input > input {
			font-size: 12rem;
			color: #fff;
		}

		.ant-picker-separator {
			color: #fff;
			font-size: 14rem;
		}

		.ant-picker-suffix {
			color: #fff;
		}

		.ant-picker-range-separator {
		}
	}
}

.cardTimeDropdown {
	background: rgba(2, 16, 27, 0.5);

	.ant-select-item {
		min-height: 22rem;
		color: #fff;
		font-size: 12rem;
		line-height: 22rem;
		padding: 0;
		text-align: center;
		padding-top: 1rem;
		padding-bottom: 1rem;
	}

	.ant-select-item-option-selected {
		background: #0d7f86;
		background: linear-gradient(
			to right,
			rgba(13, 128, 134, 0.01) 0%,
			rgba(13, 128, 134, 0.01) 1%,
			rgba(13, 128, 134, 1) 50%,
			rgba(13, 128, 134, 0.01) 100%
		);
	}

	.ant-select-item-option-active {
		background: #0d7f86;
		background: linear-gradient(
			to right,
			rgba(13, 128, 134, 0.01) 0%,
			rgba(13, 128, 134, 0.01) 1%,
			rgba(13, 128, 134, 1) 50%,
			rgba(13, 128, 134, 0.01) 100%
		);
	}
}

.cardTimeDataPick {
	.ant-picker-panel-container {
		background: rgba(2, 28, 45, 0.5);
	}

	.ant-picker-cell-disabled {
		&::before {
			background-color: rgba(191, 191, 191, 0.5);
		}

		.ant-picker-cell-inner {
			color: #bfbfbf !important;
			background-color: transparent;
		}
	}

	.ant-picker-cell {
		.ant-picker-cell-inner {
			color: #fff;
		}
	}

	.ant-picker-year-btn {
		color: #fff;
	}

	.ant-picker-header > button {
		color: #fff;
	}

	.ant-picker-cell-range-start {
		&::before {
			background-color: #0d7f86 !important;
		}
	}

	.ant-picker-cell-in-range {
		&::before {
			background-color: #0d7f86 !important;
		}
	}

	.ant-picker-cell-range-end {
		&::before {
			background-color: #0d7f86 !important;
		}
	}

	.ant-picker-range-arrow {
		&::after {
			border: 5px solid #021c2d;
			border-color: #021c2d #021c2d transparent transparent;
		}
	}

	.ant-picker-panel {
		border-color: #021c2d;
	}
}

@primary-color: #0ad0d0;