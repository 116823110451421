.tabContent {
	.ant-tabs-tab {
		margin-right: 0;
		width: 150rem;
		.ant-tabs-tab-btn {
			color: #0bb9c4;
			font-size: 16rem;
			text-align: center;
			background-image: url('../../../../../static/images/tabBG2.png');
			background-position: center;
			background-size: contain;
			background-repeat: no-repeat;
			background-size: 150rem 23rem;
			width: 150rem;
			height: 23rem;
		}
		&.ant-tabs-tab-active {
			.ant-tabs-tab-btn {
				color: #ffffff;
				font-size: 16rem;
				background-image: url('../../../../../static/images/tabBG1.png');
			}
		}
	}
	.ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-ink-bar {
		height: 1px;
		background: #06e7e9;
		bottom: 2rem;
	}
	.ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-ink-bar::after {
		content: '';
		position: absolute;
		width: 16%;
		height: 4rem;
		border-radius: 4rem;
		left: 50%;
		bottom: -2rem;
		transform: translateX(-50%);
		background-color: #fff799;
	}
	.ant-tabs-top > div.ant-tabs-nav::before {
		border: 0;
	}
	.ant-tabs-top > .ant-tabs-nav {
		margin-bottom: 0;
	}
}

@primary-color: #0ad0d0;