.listContent {
	overflow-y: auto;
	height: calc(30vh - 30rem);
	display: grid;
	grid-template-rows: repeat(auto-fill, 46rem);
	grid-template-columns: 50% 50%;
	padding-right: 15px;
}
.listContent .listItem {
	height: 46rem;
	flex-shrink: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-left: 20rem;
	padding-right: 5rem;
}
.listContent .listItem:nth-child(4n + 1) {
	/* background-color: blue; */
}
.listContent .listItem:nth-child(4n + 2) {
	/* background-color: blue; */
}
.listContent .listItem:nth-child(4n + 3) {
	background-color: rgba(88, 103, 135, 0.2);
}
.listContent .listItem:nth-child(4n + 4) {
	background-color: rgba(88, 103, 135, 0.2);
}
.itemName img {
	width: 26rem;
	margin-right: 15rem;
}
.itemName label {
	font-size: 14rem;
	color: #ffffff;
}
.listItem .itemNumber {
	font-family: LcdD;
	color: #fff799;
	font-size: 32rem;
}
