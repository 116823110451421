.video-player-container {
	position: relative;
	overflow: hidden;
	border-radius: 1px;
	width: 100%;
	height: 100%;
	color: #ffffff;
	.video-player-view {
		object-fit: fill;
		border-radius: 2px;
		width: 100%;
		height: 100%;
		box-sizing: border-box;
	}
}

.pre-video-player-mask {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 1px;
	.pre-video-player-mask-inner {
		display: flex;
		width: 100%;
		height: 100%;
		flex-direction: row;
		align-items: center;
		justify-content: space-around;
		transition: all 0.5s;
		cursor: pointer;
		.video-ctrl-pause {
			cursor: pointer;
			font-size: 0.32rem;
			display: inline-block;
			& i {
				font-size: 20px;
			}
		}
		.video-ctrl-pause.pause {
			opacity: 0;
		}
		&:hover {
			.video-ctrl-pause.pause {
				opacity: 1;
			}
		}
	}
}

.video-player-container .video-ctrl {
	transition: all 0.5s;
	width: 100%;
	height: 40px;
	position: absolute;
	bottom: 0;
	left: 0;
	transform: translateY(100%);
	background-color: transparent;
	.video-ctrl-inner {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		padding: 0 25px;
		& .video-ctrl-right,
		& .video-ctrl-left {
			display: flex;
			align-items: center;
		}
		.video-ctrl-title {
			font-size: 0.12rem;
			margin-right: 0.2rem;
		}
		.video-ctrl-max {
			cursor: pointer;
			display: inline-block;
			font-size: 0.16rem;
			line-height: 0.16rem;
		}
		.video-ctrl-cut {
			width: 28px;
			height: 28px;
			//background: url('../../static/images/video_cut.png') no-repeat;
			background-size: 100%;
			cursor: pointer;
			display: inline-block;
			margin-left: 20px;
		}
		.video-ctrl-close {
			width: 28px;
			height: 28px;
			cursor: pointer;
			margin-left: 20px;
			display: inline-block;
			font-size: 0.28rem;
		}
	}
}

.video-player-container-bg {
	width: 100%;
	height: 100%;
	position: absolute;
	background-color: transparent;
}

.video-player-container-content {
	width: 100%;
	height: 100%;
	.video-player-container-content-inner {
		position: relative;
		overflow: hidden;
		width: 100%;
		height: 100%;
		.video-ctrl {
			transform: translateY(0);
		}
	}
}

.video-player-container-content-inner:hover .video-ctrl {
	transform: translateY(0);
}

.video-player-control {
	width: 1.26rem;
	height: 1.26rem;
	position: absolute;
	right: 0.3rem;
	bottom: 0.8rem;
	z-index: 1000;
	& > span {
		width: 0.5rem;
		height: 0.5rem;
		background-color: transparent;
		position: absolute;
		display: inline-block;
		cursor: pointer;
	}
	& .control-top {
		top: 0;
		left: 50%;
		transform: translateX(-50%);
	}
	& .control-bottom {
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
	}
	& .control-left {
		top: 50%;
		left: 0;
		transform: translateY(-50%);
	}
	& .control-right {
		top: 50%;
		right: 0;
		transform: translateY(-50%);
	}
}
@font-face {
	font-family: 'iconfont'; /* Project id 2652259 */
	src: url('./iconfont.woff2') format('woff2'), url('./iconfont.woff') format('woff'),
		url('./iconfont.ttf') format('truetype');
}

.iconfont {
	font-family: 'iconfont' !important;
	font-size: 16px;
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.iconfont {
	font-family: 'iconfont' !important;
	font-size: 16px;
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-zantingtingzhi:before {
	content: '\a002';
}

.icon-yinleicon_huaban1fuben10:before {
	content: '\a001';
}

.icon-quanping:before {
	content: '\a000';
}

@primary-color: #0ad0d0;