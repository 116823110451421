/*
项目配置css
*/
@import '~antd/dist/antd.less';
@import './theme.less';


* {
    box-sizing: border-box;
}

#root {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: relative;
}

/*.login-page {*/
/*	background-image: linear-gradient(120deg, #fedcba, #abcdef);*/
/*}*/
/*input {*/
/*	background: none;*/
/*	outline: none;*/
/*	border: none;*/
/*}*/
/*input:focus {*/
/*	border: none;*/
/*}*/
input::placeholder {
    color: #aaaaaa;
}

i {
    font-style: normal;
}

.ant-modal-close-x {
    color: #ccc;
}

.ant-modal-body {
    max-height: 600px;
    overflow: hidden;
}

.ant-tree-list {
    //max-height: 570px;
    max-height: calc(100% - 30px);
    height: calc(100% - 30px);
    overflow: hidden;
    overflow-y: auto;
}

.assign-modal-main {
    width: 100%;
}

.ant-tree {
    width: 100%;
}

.assign-modal-inner {
    max-height: calc(100% - 148px);
    overflow: hidden;
    height: 600px;
}

.ant-tree-list-holder {
    max-height: calc(100% - 200px);

    &>div {
        height: calc(100% - 200px);
    }
}

.assign-modal-inner .assign-modal-main {
    //flex: 1;
    height: calc(100% - 32px);

    .ant-tree-list {
        height: calc(100% - 30px);
    }
}

//.ant-tree-list-holder-inner{
//	max-height: calc(100% - 100px);
//}
.ant-modal-body {
    display: flex;
    padding: 24px 0;

    .__temp__page {
        height: 500px;
    }

    .__temp__page_wrapper {
        max-height: calc(100% - 92px) !important;
    }
}

.person-table,
.car-table {
    width: calc(100% - 270px);
}

.location-modal {
    .ant-modal-content {
        width: 100%;
        height: 100%;

        .ant-modal-body {
            height: 100%;
            max-height: 100%;
        }
    }
}

.info-deal-common {
    height: calc(100% - 60px);

    .iot-form {
        max-height: 100%;

        .form-item-view {
            max-height: 100%;
        }
    }
}

.incept-success-modal {
    width: 100%;
}

.form-reset-password {
    padding: 0 24px;
}
@primary-color: #0ad0d0;