.map-container {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 200;
	overflow: hidden;
}

.map-container-inner {
	position: relative;
	width: 100%;
	height: 100%;
}

@primary-color: #0ad0d0;