.modalContainer {
	width: 396rem;
	min-height: 380rem;
	background-image: linear-gradient(
		to bottom,
		rgba(11, 92, 98, 0.6) 0,
		rgba(5, 23, 43, 0.7) 15%,
		rgba(5, 23, 43, 0.7) 100%
	);
	box-shadow: 0 0 10px 1px rgba(11, 92, 98, 0.6);
	color: #fff;
}
.content {
	padding: 0 20px;
	color: #fff;
	width: 100%;
	word-break: break-all;
	max-height: 55vh;
	overflow: auto;
}
.halfRowOptionItem {
	display: inline-flex;
	width: 50%;
	padding: 5px;
}
.fullRowOptionItem {
	display: flex;
	padding: 5px;
}
.itemTitle {
	flex: none;
	margin-right: 10px;
	width: 70px;
}
.mediaContainer {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}
.mediaContainer div {
	margin: 5rem;
}
.fileDownLoad {
	display: inline-block;
	margin-right: 10px;
	text-decoration-line: underline;
}
.tableContainer {
	flex: 1;
}
.tableContent {
	border-collapse: collapse;
	margin-bottom: 4px;
	margin-top: 4px;
}
.tableRowItem {
	color: #fff;
	border: 1px solid #d4d4d4;
	vertical-align: top;
	width: 25%;
}
thead .tableRowItem {
	padding: 3px;
}
tbody .tableRowItem {
	line-height: 2em;
	min-width: 24px;
	padding: 5px;
	padding-top: 7px;
	padding-bottom: 7px;
}
