.device-check_box {
	position: absolute;
	bottom: 40rem;
	left: 500rem;

	// height: 400rem;
	width: 160rem;
	.ant-checkbox-wrapper + .ant-checkbox-wrapper {
		margin-left: 0;
	}
	.ant-checkbox-wrapper {
		display: block;
		color: #fff;
		font-size: 14rem;
		margin-top: 20rem;
	}
	.ant-checkbox-inner {
		background: rgba(160, 163, 164, 1);
		border: 1rem solid #d2d2d2;
	}

	.ant-checkbox::after {
		border: none;
	}
	.ant-checkbox-checked {
		.ant-checkbox-inner {
			background-color: #fff;
		}
	}
	.ant-checkbox-checked .ant-checkbox-inner::after {
		position: absolute;
		display: table;
		border: 2px solid #1b1b1b;
		border-top: 0;
		border-left: 0;
		transform: rotate(45deg) scale(1) translate(-50%, -50%);
		opacity: 1;
		transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
		content: ' ';
	}
	// .ant-checkbox-inner::after{
	//     border: 2px solid #fff;
	// }
}
.marker_total {
	position: relative;

	span {
		position: absolute;
		background-color: red;
		top: -20rem;
		left: 20rem;
		width: 29rem;
		height: 29rem;
		border-radius: 50%;
		color: #fff;
		text-align: center;
		line-height: 29rem;
		z-index: 101;
	}
}

.modal-poi-dev-list {
	.ant-modal-close {
		display: block;
	}
	.ant-modal-content {
		width: 230px;
		background-color: transparent;
	}
	.ant-modal-body {
		width: 230px;
		height: 353px;
		//background-color: transparent;
		background: rgba(2, 16, 27, 0.86);
		padding-left: 0px !important;
		// opacity: 0.86;
		.ant-modal-confirm-body-wrapper {
			width: 100%;
			height: 100%;
		}
		.ant-modal-confirm-body {
			width: 100%;
			height: 100%;
		}
		.ant-modal-confirm-content {
			.gary-class {
				filter: grayscale(100%);
			}
			margin-top: 15rem;
			color: #fff;
			//height: 300rem;
			overflow-y: auto;
			width: 100%;
			height: 100%;
			& > span {
				display: flex;
				flex-direction: column;
			}
			//div {
			//	width: 200rem;
			//	padding-left: 23rem;
			//	// margin-right:22rem;
			//	height: 40rem;
			//	line-height: 40rem;
			//	font-size: 14px;
			//	border-bottom: 1px solid rgba(255, 255, 255, 0.27);
			//}
			.dev-list-item {
				width: 100%;
				height: 40px;
				line-height: 40px;
				font-size: 14px;
				border-bottom: 1px solid rgba(255, 255, 255, 0.27);
				display: flex;
				flex-direction: row;
				align-items: center;
				padding-left: 15px;
				& > img {
					width: 27px;
					height: 27px;
					margin: 0;
					padding: 0;
				}
				.name {
					width: calc(100% - 40px);
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					cursor: pointer;
					padding-left: 10px;
				}
			}
			div:last-child {
				border: none;
			}
		}
	}
	.ant-modal-confirm-btns {
		display: none;
	}
	.ant-modal-close-x {
		height: 20rem;
		width: 30rem;
		line-height: 36rem;
	}
}

@primary-color: #0ad0d0;