
.box {
	display: flex;
	flex-direction: column;
}

.boxTitle {
	margin-top: 13rem;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding-bottom: 1vh;
}

.boxTitle > span:nth-child(1) {
	transform: skewX(-5deg);
	font-size: 20rem;
	color: rgba(255, 255, 255, 1);
}

.boxTitle > span:nth-child(2) {
	/* margin-left: 20rem; */
	display: flex;
	font-size: 30rem;
	transform: skewX(-10deg);
	color: rgba(255, 247, 153, 1);
}

.bottom {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.right {
	margin-left: 20rem;
}

.right > div {
	position: relative;
	margin-bottom: 20rem;
	padding: 0 16rem 0 30rem;
	display: flex;
	flex-direction: row;
	/* justify-content: center; */
	align-items: center;
	height: 30rem;
	background: rgba(48, 86, 145, 0.3);
}

.right > div::before {
	content: ' ';
	position: absolute;
	top: 50%;
	left: 13rem;
	transform: translateY(-50%);
	width: 8rem;
	height: 8rem;
	border-radius: 8rem;
}

.right > div:nth-child(1)::before {
	background: rgba(80, 166, 239, 1);
}

.right > div:nth-child(2)::before {
	background: rgba(253, 164, 0, 1);
}

.right > div:nth-child(3)::before {
	background: rgba(90, 208, 73, 1);
}

.right > div > span:nth-child(1) {
	font-size: 16rem;
	font-weight: 400;
}

.right > div:nth-child(1) > span:nth-child(1) {
	display: flex;
	text-align: left;
	color: #50a6ef;
	width: 96rem;
}

.right > div:nth-child(2) > span:nth-child(1) {
	display: flex;
	text-align: left;
	color: #fda700;
	text-align: left;
	width: 96rem;
}

.right > div:nth-child(3) > span:nth-child(1) {
	display: flex;
	text-align: left;
	color: #5ad049;
	width: 96rem;
}

.right > div > span:nth-child(2) {
	margin-left: 18rem;
	font-size: 24rem;
	font-weight: 500;
}

.right > div:nth-child(1) > span:nth-child(2) {
	color: #50a6ef;
}

.right > div:nth-child(2) > span:nth-child(2) {
	color: #fda400;
}

.right > div:nth-child(3) > span:nth-child(2) {
	color: #5ad049;
}

@media screen and (max-height: 1079px) {
	.mediaBox {
		height: 28vh !important;
	}
}
