.pageContent {
	width: 100%;
	height: 100%;
}

.carTotal {
	text-align: center;
	font-size: 24rem;
	color: #ffffff;
}

.carTotal .carNumber {
	font-size: 30rem;
	color: #fff799;
}

.carTotal .carUnit {
	font-size: 16rem;
}

.carContent {
	display: flex;
	justify-content: space-between;
}

.carContent .carItem {
	flex: 1;
	flex-shrink: 0;
}

.carInfoContent {
	margin-top: 20rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	color: #ffffff;
	font: 16rem;
}

.carInfoBlock {
	position: relative;
	width: 125rem;
	text-align: center;
}

.carInfoBlock::before {
	content: '';
	width: 90%;
	height: 1px;
	background-color: #ffffff;
	position: absolute;
	right: 0;
	top: 25rem;
}

.carInfoBlock::after {
	content: '';
	width: 6rem;
	height: 6rem;
	border-radius: 50%;
	background-color: #ffffff;
	position: absolute;
	right: 0;
	top: 23rem;
	z-index: 9;
}

.carInfoBlock .carInfoContentTop {
	line-height: 1;
	position: relative;
	margin-bottom: 15rem;
}

.carInfoBlock .carInfoContentTop::before {
	content: '';
	display: inline-block;
	position: absolute;
	top: 0;
	left: 0;
	width: 5rem;
	height: 7rem;
	border-top: 2rem solid #fff;
	border-left: 2rem solid #fff;
}

.carInfoBlock .carInfoContentTop::after {
	content: '';
	display: inline-block;
	position: absolute;
	top: 0;
	right: 0;
	width: 5rem;
	height: 7rem;
	border-top: 2rem solid #fff;
	border-right: 2rem solid #fff;
}

.carInfoBlock .carInfoContentBottom {
	color: #06e7e9;
	font-size: 24px;
	position: relative;
	margin-bottom: 0;
	line-height: 1;
}

.carInfoBlock .carInfoContentBottom > span {
	font-size: 16px;
}

.carInfoBlock .carInfoContentBottom::before {
	content: '';
	display: inline-block;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 5rem;
	height: 7rem;
	border-bottom: 2rem solid #fff;
	border-left: 2rem solid #fff;
}

.carInfoBlock .carInfoContentBottom::after {
	content: '';
	display: inline-block;
	position: absolute;
	bottom: 0;
	right: 0;
	width: 5rem;
	height: 7rem;
	border-bottom: 2rem solid #fff;
	border-right: 2rem solid #fff;
}

.securityList {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	height: 270rem;
	background-image: url('../../../../static/images/securityBG.png');
	background-size: 236rem 202rem;
	background-position: center;
	background-repeat: no-repeat;
	padding-bottom: 30rem;
}

.securityList > .item {
	width: 50%;
	height: 50%;
	padding-left: 30rem;
	padding-top: 48rem;
	display: flex;
	flex-direction: column;
}

.securityList > .item:nth-child(even) {
	align-items: flex-end;
	padding-right: 30rem;
	padding-left: 0;
}

.securityList > .item > p {
	height: 16rem;
	font-size: 16rem;
	line-height: 16rem;
	color: #fff;
	padding-left: 18rem;
	margin-bottom: 6rem;
	background-image: url('../../../../static/images/securityIcon1.png');
	background-size: contain;
	background-repeat: no-repeat;
}

.securityList > .item:nth-child(2) > p {
	background-image: url('../../../../static/images/securityIcon2.png');
}

.securityList > .item:nth-child(3) > p {
	background-image: url('../../../../static/images/securityIcon3.png');
}

.securityList > .item:nth-child(4) > p {
	background-image: url('../../../../static/images/securityIcon4.png');
}

.securityList > .item .itemNumber {
	font-family: 'LcdD';
	font-size: 36rem;
	color: #06e7e9;
}

.securityList > .item:nth-child(1) .itemNumber {
	color: #e60012;
}

.securityList > .item:nth-child(2) .itemNumber {
	color: #e60012;
}

.securityList > .item .itemNumber .unit {
	color: #ffffff;
	font-size: 14rem;
}

.persionerTotal {
	text-align: center;
	font-size: 24rem;
	color: #ffffff;
	margin-top: 12rem;
}

.persionerTotal .persionerNumber {
	font-size: 30rem;
	color: #fff799;
}

.persionerTotal .persionerUnit {
	font-size: 16rem;
}

.chartInfo {
	display: flex;
}

.chartInfo .infoList {
	margin-top: 10rem;
	width: 190rem;
}

.chartInfo .infoList .infoItem {
	position: relative;
	height: 32rem;
	line-height: 32rem;
	margin-bottom: 12rem;
	padding-left: 40rem;
	background-color: rgba(48, 86, 145, 0.5);
	display: flex;
	align-items: center;
}

.chartInfo .infoList .infoItem::before {
	content: '';
	position: absolute;
	width: 8rem;
	height: 8rem;
	border-radius: 50%;
	top: 50%;
	transform: translateY(-50%);
	left: 20rem;
	background-color: #50a6ef;
}

.chartInfo .infoList .infoItem:nth-child(2):before {
	background-color: #f1cd49;
}

.chartInfo .infoList .infoItem:nth-child(3):before {
	background-color: #47feae;
}

.chartInfo .infoList .infoItem > label {
	color: #50a6ef;
	font-size: 16rem;
}

.chartInfo .infoList .infoItem > span {
	margin-left: 20rem;
	color: #50a6ef;
	font-size: 24rem;
}

.chartInfo .infoList .infoItem:nth-child(2) > label {
	color: #f1cd49;
}

.chartInfo .infoList .infoItem:nth-child(3) > label {
	color: #47feae;
}

.chartInfo .infoList .infoItem:nth-child(2) > span {
	color: #f1cd49;
}

.chartInfo .infoList .infoItem:nth-child(3) > span {
	color: #50a6ef;
}

.visiterToggle {
	position: absolute;
	right: 0;
	top: 0.15vh;
	z-index: 99;
	display: flex;
}
.visiterToggle > div {
	padding: 0.4vh 0.4vw 0.4vh 0.4vw;
	display: flex;
	cursor: pointer;
	user-select: none;
	justify-content: center;
	align-items: center;
	background: url('../../../../static/images/accessInfoBg.png') center no-repeat;
	background-size: 100% 100%;
	color: #fff;
	font-size: 12rem;
}

.left {
	background: url('../../../../static/images/accessInfoActive.png') center no-repeat !important;
	background-size: 100% 100%;
	margin-right: -2px;
}
.right {
	background: url('../../../../static/images/accessInfoActive.png') center no-repeat !important;
	background-size: 100% 100%;
	margin-left: -2px;
}
