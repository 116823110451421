.pro-box {
 
  display: flex;
  flex-direction: column;
  padding-top: 30rem ;

  &>div {
    padding-left: 10rem;
    margin-top: 16rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #fff;

    &>div {
      width: 300rem;
      height: 15rem;
      background: #9bbf43;
      border-radius: 0 15rem 15rem 0;
    }

  }
}
@primary-color: #0ad0d0;