.tableMum {
	position: absolute;
	right: 0;
	top: -52rem;
	right: 4rem;
	font-size: 16rem;
	color: #fff799;
	line-height: 30rem;
}

.table > div:nth-child(2) {
	height: 17vh;
	overflow-y: hidden;
}

.table > div:nth-child(1) {
	margin-top: 22rem;
	display: flex;
	flex-direction: row;
	font-size: 16px;
	color: #00a0e5;
}

.table > div:nth-child(1) span {
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
}

.longView {
	display: flex;
	flex-direction: column;
	width: 100%;
	/* height: 36rem; */
}

.longView > .tableItem {
	display: flex;
	flex-direction: row;
	height: 36rem;
}
.longView > .tableItem > .tableItemMiddle {
	display: inline-block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	line-height: 36px;
}

.longView > div > span {
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
}

.longView > div > span:nth-child(1) {
	font-size: 16rem;
	color: #fff;
}

.longView > div > span:nth-child(2),
.longView > div > span:nth-child(3) {
	font-size: 13rem;
	color: #fff;
}

.table .green {
	font-size: 13rem;
	color: rgba(3, 230, 3, 1) !important;
}

.longView > div:nth-child(2n-1) {
	background: rgba(88, 103, 135, 0.2);
}


@media screen and (max-height: 1080px) {
	.mediaBox {
		height: 26.6vh;
		bottom: 2vh;
	}
	.table > div:nth-child(2) {
		height: 17vh;
		overflow-y: hidden;
	}
}

@media screen and (max-height: 1079px) {
	.mediaBox {
		bottom: 0.5vh;
	}
	.table > div:nth-child(2) {
		height: 16vh;
		overflow-y: hidden;
	}
}

@media screen and (max-height: 900px) {
	.mediaBox {
		bottom: 0vh;
	}
}
