.con-video-info {
	padding: 16rem;
	color: #fff;
	padding-left: 7rem;
	display: grid;
	grid-template-columns: 193rem 193rem;
	grid-template-rows: 172rem;
	grid-auto-rows: 172rem;
	gap: 10rem 10rem;
	.video-info-item {
		background: #000000;
	}
	.no-online {
		padding-top: 15rem;
		padding-left: 5rem;
		.info {
			padding-top: 30rem;
			text-align: center;
			color: #ccc;
		}
	}
	.no-video {
		place-self: center end;
	}
}

@primary-color: #0ad0d0;