.list {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	height: 100%;
}

.listCard {
	flex: 1;
	background-image: url('../../../../../static/images/spaceBG.png');
	background-size: contain;
	background-position: 50% 90%;
	background-repeat: no-repeat;
	height: 100%;
	padding-top: 10rem;
	padding-bottom: 12rem;
}

.listName {
	font-size: 16rem;
	color: #fff799;
	text-align: center;
	margin-bottom: 0rem;
	padding: 2vh 0;
}

.listNumber {
	font-family: 'LcdD';
	font-size: 36rem;
	color: #06e7e9;
	text-align: center;
	line-height: 36rem;
	padding: 0vh 0;
}

.listNumber span {
	font-size: 14rem;
}
