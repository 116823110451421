.videoInfoBody {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.videoInfoBody .videoList {
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
}
.videoInfoBody .videoList .videoListItem {
	flex: 1;
	margin: 16rem 12rem;
}
.videoListItem .emptyVideoItem {
	width: 270rem;
	height: 180rem;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #ffffff;
}
.videoListItem .videoListItemInner {
	width: 100%;
	height: 100%;
}

.fullscreen {
	font-size: 20rem;
}
.mediaBox {
	width: 920rem;
}
