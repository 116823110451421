.convenient-access-event-type{
    display: flex;
    flex-direction: column;
    align-items: center;
    .convenient-access-event-type-top{
        margin-top:1vh;
        display: flex;
        flex-direction: row;
        &>div{
            display: flex;
            flex-direction: column;
            height:90rem;
            width:90rem;
            align-items: center;
            justify-content: center;
        
            font-size: 14rem;
            color: #fff;
            &>span:nth-child(1){
                color: #24D2DA;
                font-size: 22rem;
                line-height: 1;
                font-family: 'LcdD'
            }
        }
        &>div:nth-child(2){
            margin:0 40rem;
        }
        &>div:nth-child(1) {
            background:url('../../../static/images/registervisitorsBg.png') center no-repeat;
            background-size:100% 100%;
        }
        &>div:nth-child(2) {
           background:url('../../../static/images/registervisitorsBg3.png') center no-repeat;
           background-size:100% 100%;
        }
        &>div:nth-child(3) {
           background:url('../../../static/images/registervisitorsBg2.png') center no-repeat;
           background-size:100% 100%;
        }
    }
    .convenient-access-event-type-bottom{
        margin-top:10rem;
        width:350rem;
        p{
            margin:0;
        }

        &>div{
            position: relative;
            height:35rem;
            &>div{
                position: relative;
                // width:
                // position: absolute;
                &>span:nth-child(1){
                    font-size: 14rem;
                    color: #fff;
                    span{
                        color:#06E7E9
                    }
                }
                &>span:nth-child(2){
                    position: absolute;
                    right: 0;
                    color: #fff;
                    &>span{
                        color: #FDA400;
                    }
                }
            }
             &>.ant-progress {
                position: absolute;
                top:10rem;
            }
        }
    }
}
@primary-color: #0ad0d0;