.statistical_trends {
    padding:16rem;
    color:#fff;
    p{
        margin-bottom: 6rem;
        span{
            margin-right: 10rem;
        }
         span:nth-child(2){
             margin-left: 10rem;
         }
    }
     .statistical_trends_title {
         text-align: center;
         font-size: 16rem;
         font-weight: 400;
         color: #FFFFFF;
         line-height: 48rem;
         position: relative;
         span{
            position: absolute;
            right: 10rem;
            top:4rem;
            font-size: 12rem;
            color: #00FFFF;
         }
     }
    div{
    
        .statistical_trends_tips{
            padding:0 10rem;
            display: flex;
            justify-content: space-between;
            font-size: 12rem;
            color: #00FFFF;
        }
    }
}
@primary-color: #0ad0d0;