.app-page {
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	position: relative;
	display: flex;
	flex-direction: column;
}
.app-header {
	width: 100vw;
	height: 120rem;
	display: flex;
	flex-direction: row;
	//align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 250;
	color: #ffffff;
	background-image: url('./static/images/appheaderbg.png');
	background-size: 100vw 14rem;
	background-position: 0 92rem;
	background-repeat: no-repeat;
}

.route-container {
	height: 100%;
	padding-top: 100rem;
	position: relative;
	width: 100%;
	.noPage {
		font-size: 24px;
		color: #ffffff;
		z-index: 999;
		position: absolute;
	}
}
.app-area-select {
	position: absolute;
	top: 110rem;
	left: 40rem;
	width: 470rem;
	display: flex;
	flex-direction: row;
	align-items: center;
	z-index: 300;
	.area-select-view {
		width: 396rem;
		height: 32px;
		padding-left: 15rem;
	}
	.icon-dingwei {
		color: #00ffff;
		font-size: 28rem;
	}
}
.poi-active {
	color: #11f928;
	font-size: 24px;
}
.modal-poi-view {
	.ant-modal-content {
		background-color: transparent;
		box-shadow: none;
		position: relative;
	}
	.ant-modal-confirm-btns {
		display: none;
	}
	.ant-modal-close {
		display: block;
		position: absolute;
		top: 5px;
		right: 0;
	}
}
.ant-modal-confirm-info .ant-modal-confirm-body > .anticon {
	display: none !important;
}
.ant-modal-confirm .ant-modal-body {
	padding: 10px !important;
}
.ant-radio-wrapper {
	color: #fff;
}
.ant-radio-inner::after {
	background-color: #0ad0d0;
}
.ant-radio-checked .ant-radio-inner {
	border-color: #0ad0d0;
}

.ant-radio-inner {
	background-color: transparent;
}
* {
	user-select: none;
}
.icon-taiyang {
	color: #f5e05b;
}
.icon-duoyun {
	color: #1479db;
}
.icon-xiayu {
	color: #7ec4ff;
}
.time-picker-poi {
	display: flex;
	border: 0 none;
	color: #fff;
	background-color: transparent;
	flex-direction: column;
	.ant-picker-range-separator {
		display: none;
	}
	.ant-picker-active-bar {
		display: none;
	}
	.ant-picker-suffix {
		display: none;
	}
	.ant-picker-input {
		border: 1px solid #092b29;
		margin-bottom: 4rem;
	}
	.ant-picker-input > input {
		color: #fff;
	}
	.ant-picker-input {
		padding-right: 20px;
	}
}
.time-picker-poi .ant-picker:hover,
.ant-picker-focused {
	border: 0 none;
}
.drop-time-picker-poi {
	* {
		border-color: rgba(100, 100, 100, 0.4) !important;
	}
	.ant-picker-range-arrow {
		display: none;
		opacity: 0;
	}
	.ant-picker-panel-container {
		background-color: transparent;
		background-image: linear-gradient(
			to bottom,
			rgba(11, 92, 98, 0.6) 0,
			rgba(5, 23, 43, 0.7) 15%,
			rgba(5, 23, 43, 0.7) 100%
		);
		color: #fff;
		* {
			background-color: transparent;
			color: #fff;
			border-color: grba(100, 100, 100, 0.4) !important;
		}
	}
	.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
		background-color: #1a5d83;
	}
	.ant-picker-time-panel {
	}
	.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
	.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end)
		.ant-picker-cell-inner {
		background-color: #1a5d83;
	}
	.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
		background-color: #1a5d83;
	}
	.ant-picker-time-panel-cell-disabled {
		opacity: 0.4;
	}
	.ant-picker-cell-disabled {
		background-color: transparent;
		opacity: 0.4;
	}
	.ant-picker-cell-disabled::before {
		background-color: transparent;
	}
	.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
		color: #fff;
	}
	.ant-picker-panel-container {
	}
	.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
		background-color: #1a5d83;
	}
	.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
	.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
		background-color: #1a5d83;
	}
}
/* 设置滚动条的样式 */

::-webkit-scrollbar {
	/*滚动条整体样式*/
	width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
	height: 1px;
}

::-webkit-scrollbar-thumb {
	/*滚动条里面小方块*/
	border-radius: 10px;
	// -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	background: #818486;
}

::-webkit-scrollbar-track {
	/*滚动条里面轨道*/
	// -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	border-radius: 10px;
	background: #45484b;
}

.poi-offline {
	color: #ddd;
}
.highcharts-credits {
	display: none;
}
.poi-actived {
	color: #ec9717;
}
.patrolclassname {
	padding-bottom: 0;
	background-image: linear-gradient(
		to bottom,
		rgba(11, 92, 98, 0.6) 0,
		rgba(5, 23, 43, 0.7) 15%,
		rgba(5, 23, 43, 0.7) 100%
	);
	box-shadow: 0 0 10px 1px rgba(11, 92, 98, 0.6);
	/*padding-top: ;*/
	color: #f2f2f2;
	* {
		background-color: transparent;
		color: #f2f2f2;
	}
	.ant-modal-confirm-content {
		color: #f2f2f2;
	}
	.ant-modal-confirm-btns {
		display: none;
	}
	.ant-modal-confirm-body-wrapper {
		width: 100%;
	}
	.ant-modal-confirm-title {
		text-align: center;
		color: #f2f2f2;
		font-size: 14px;
	}
}
.confirm-modal {
	width: 100%;
	padding: 10px 20px;
	position: relative;
	.confirm-modal-close {
		position: absolute;
		top: 0;
		right: 0;
		color: #999;
		font-size: 14px;
		transform: translateY(-30px);
		cursor: pointer;
	}
	.confirm-modal-close22 {
		transform: translateY(0);
		right: 10px;
	}
	.confirm-btn {
		padding: 3px 12px;
		margin: 0 10px;
		border-radius: 2px;
		cursor: pointer;
	}
	.cancel-btn {
		background-color: #072f36;
	}
	.ok-btn {
		background-color: #0c4c55;
	}
	.confirm-modal-btns {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		margin-top: 10px;
	}
}
.modal-poi-view-2 {
	.ant-modal-close {
		display: none;
	}
}
.patrol-line-view {
	padding: 0;
	.ant-modal-body {
		position: relative;
		height: 60vh;
	}
}

.videoInfoContent {
	.pre-video-player-mask {
		.pre-video-player-mask-inner {
			display: block;
			position: relative;
			.video-ctrl-pause {
				position: absolute;
				left: 7rem;
				bottom: 2rem;
				display: flex;
			}
		}
	}
	.video-ctrl {
		position: absolute;
		width: 10rem;
		right: 0;
		left: auto;
		.video-ctrl-inner {
			padding: 0;
			.video-ctrl-left {
				display: none;
			}
		}
		.video-ctrl-max {
			opacity: 0;
			position: absolute;
			right: 7rem;
			bottom: 2rem;
		}
	}
	.video-player-container-content-inner {
		.pre-video-player-mask {
			position: absolute;
			width: 100%;
			height: 20rem;
			bottom: 0;
			top: auto;
			background-color: rgba(0, 0, 0, 0.5);
			opacity: 0;
			.pre-video-player-mask-inner {
				background-color: transparent !important;
			}
		}
		.icon-zantingtingzhi {
			display: inline-block;
			width: 16rem;
			height: 16rem;
			background-image: url('./static/images/toStop.png');
			background-size: contain;
			&::before {
				content: '';
			}
		}
		.icon-quanping {
			display: inline-block;
			width: 16rem;
			height: 16rem;
			background-image: url('./static/images/toBig.png');
			background-size: contain;
			&::before {
				content: '';
			}
		}

		.icon-yinleicon_huaban1fuben10 {
			display: inline-block;
			width: 16rem;
			height: 16rem;
			background-image: url('./static/images/toStart.png');
			background-size: contain;
			&::before {
				content: '';
			}
		}
		&:hover {
			.video-ctrl-pause.pause {
				opacity: 1;
			}
			.video-ctrl-max {
				opacity: 1;
			}
			.pre-video-player-mask {
				opacity: 1;
			}
		}
	}
	.fullscreen {
		.icon-quanping {
			background-image: url('./static/images/toSmall.png') !important;
		}
	}
}

@primary-color: #0ad0d0;