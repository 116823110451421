.convenient-access-live{
    display: flex;
    flex-direction: column;
    h3{
        color: #fff;
        font-size: 20rem;
        font-style:italic;
        font-weight: 100;
        width:100%;
        text-align: center;
        margin-top:0rem;
        span{
            font-size: 24rem;
            color: #FFF799;
        }
    }
}
@primary-color: #0ad0d0;