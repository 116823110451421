.basic_information{
    color: #fff;
    margin-left: 42rem;
    font-size: 14rem;
    margin-top:10rem;
    p{
        line-height: 25px;
        span:nth-child(1){
            display: inline-block;
            width:7em;
        }
    }
    img{
        margin-top:30rem;
        width: 340rem;
        height: 225rem;
    }
}
@primary-color: #0ad0d0;