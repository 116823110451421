.device_detail {
	position: fixed;
	bottom: 0;
	right: 0;
	z-index: 2000;
	width: 430rem;
	height: calc(100vh - 100rem);
	background-color: rgba(4, 41, 69, 0.7);
	transition: all 0.5s;
	transform: translateX(100%);
	color: #fff;
	overflow-y: auto;
	.title-name {
		margin: 5px 0;
		background-image: url('../../../static/images/patrolTitleIcon.png');
		background-repeat: no-repeat;
		background-position: 15px 2px;
		text-indent: 40px;
		border-bottom: 1rem solid #d3d3d3;
	}
	.ant-tabs-nav {
		margin: 0;
		padding-left: 30rem;
		height: 30rem;
		background-image: linear-gradient(
			to right,
			rgba(6, 231, 233, 0) 0%,
			rgba(6, 231, 233, 0.28) 30%,
			rgba(6, 231, 233, 0.38) 50%,
			rgba(6, 231, 233, 0.28) 70%,
			rgba(6, 231, 233, 0) 100%
		);
	}
	.ant-tabs-top > .ant-tabs-nav::before,
	.ant-tabs-bottom > .ant-tabs-nav::before,
	.ant-tabs-top > div > .ant-tabs-nav::before,
	.ant-tabs-bottom > div > .ant-tabs-nav::before {
		display: none;
	}
	.ant-tabs-tab {
		color: rgba(239, 239, 239, 1);
		margin-right: 22rem;
		//padding-bottom: 5rem;
	}
	.device-image {
		background-image: url('../../../static/images/devicePape.png');
		background-repeat: no-repeat;
		background-size: 100% 100%;
		// background-position: 15px 2px;
		// text-indent: 40px;
		// border-bottom: 1rem solid #d3d3d3;
		margin-top: 1vh;
		width: 100%;
		height: 270rem;
	}
}
.show-device-detail {
	transform: translateX(0);
}

@primary-color: #0ad0d0;