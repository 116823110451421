.pageContent {
	width: 100%;
    height: 100%;
}

.list {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	height: calc(28vh - 30rem);
	padding-bottom: 5px;
}

.listCard {
	background-image: url('../../../../static/images/spaceBG.png');
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	width: 33.333%;
	height: 100%;
	padding-top: 10rem;
	padding-bottom: 12rem;
}

.listName {
	font-size: 16rem;
	color: #fff799;
	text-align: center;
	margin-bottom: 0rem;
	padding: 2vh 0;
}

.listNumber {
	font-family: 'LcdD';
	font-size: 36rem;
	color: #06e7e9;
	text-align: center;
	line-height: 36rem;
	padding: 0vh 0;
}

.listNumber span {
	font-size: 14rem;
}

.infoList {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	height: 194rem;
	background-image: url('../../../../static/images/infoBG.png');
	background-size: 488rem 480rem;
	background-position: center;
	padding-bottom: 30rem;
}

.infoList > .item {
	position: relative;
	width: 50%;
	height: 50rem;
	padding-left: 36rem;
	padding-top: 15rem;
}

.infoList > .item::before {
	content: '';
	position: absolute;
	left: 20rem;
	bottom: 0;
	width: 18rem;
	height: 4rem;
	border-radius: 4rem;
	background-color: #e60012;
	z-index: 1;
}

.infoList > .item::after {
	content: '';
	position: absolute;
	left: 20rem;
	bottom: 0;
	width: calc(90% - 20rem);
	height: 1px;
	border-radius: 4rem;
	background-color: #02a1e5;
}

.infoList .itemName {
	font-size: 16rem;
	color: #fff;
}

.infoList .itemNumber {
	font-size: 16rem;
	color: #06e7e9;
}

.buildingList {
	display: grid;
	justify-content: space-between;
	grid-template-columns: 1fr 1fr;
	flex-wrap: wrap;
	background-image: url('../../../../static/images/buildInfoBG.png');
	background-size: 373rem 289rem;
	background-position: center;
	background-repeat: no-repeat;
	height: calc(26.2vh);
}

.buildingList > .item {
	width: 50%;
	height: 50%;
	display: flex;
	align-items: center;
}

.buildingList > .item:nth-child(1) {
	margin-left: 1.5vw;
	margin-top: 2vh;
}
.buildingList > .item:nth-child(2) {
	margin-left: 5vw;
	margin-top: 2vh;
}
.buildingList > .item:nth-child(3) {
	margin-left: 1.5vw;
	margin-top: 2vh;
}
.buildingList > .item:nth-child(4) {
	margin-left: 5vw;
	margin-top: 2vh;
}
.buildingList > .item:nth-child(5) {
	margin-left: 1.5vw;
	margin-top: 2vh;
}
.buildingList > .item:nth-child(6) {
	margin-left: 5vw;
	margin-top: 2vh;
}

.buildingList > .item > p {
	font-size: 16.25rem;
	color: #fff;
	background-size: contain;
	background-repeat: no-repeat;
	margin: 0;
}

.buildingList > .item > p:nth-child(even) {
	color: blue;
}

.buildingList > .item > span {
	color: #06e7e9;
	/* font-family: LcdD; */
	font-size: 16.25rem;
	line-height: 1;
	padding-left: 18rem;
}

.buildingList > .item > span .unit {
	font-size: 16px;
}

