.view-one-view {
    width: 246px;
    height: 164px;
    background-color: #ccc;
    margin: 1px;
    overflow: hidden;
    position: relative;
}

.view-one-view:hover {
    .view-one-name {
        transform: translateY(0);
    }

    .view-one-ctrl {
        transform: translateY(0);
    }
}

.video-patrol-more-view {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}

.video-patrol-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.video-patrol-modal-vide-list {
    margin-right: 20px;
    width: 160px;
}

.view-one-name {
    position: absolute;
    width: 135px;
    height: 25px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    line-height: 25px;
    transform: translateY(-100%);
    transition: all 0.5s;
    padding: 0 10px;
    top: 0;
}

.view-one-ctrl {
    position: absolute;
    width: 135px;
    height: 18px;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    line-height: 18px;
    transform: translateY(100%);
    transition: all 0.5s;
    padding: 0 3px;
    bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.view-one-ctrl-left {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.video-patrol-modal-vide-list-item {
    padding: 13px 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 1px solid rgba(8, 130, 145, 1);
    border-bottom: 0 none;
}

.video-patrol-modal-right-video-item-crtl {
    width: 12px;
    height: 12px;
    // margin: 0 4px;
    cursor: pointer;
    margin: 2px;

    .iconfont {
        font-size: 12px;
        line-height: 12px;
    }
}

.active-item-video {
    background-color: rgba(8, 130, 145, 1) !important;
}

.video-patrol-modal-vide-list {
    max-height: 300px;
    min-width: 70px;
    overflow-x: hidden;
    overflow: auto;
    color: #fff;
}

.view-one-tip {
    position: absolute;
    height: 100%;
    top: 0;
    background-color: #444;
    width: 100%;
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.video-patrol-content {
    padding: 10px;
}

.modal-more-view {
    // box-shadow: ;
    top: 164px !important;
    right: 80px;

    box-shadow: 0 0 10px 1px rgba(11, 92, 98, 0.6);
    background-image: linear-gradient(to bottom,
            rgba(11, 92, 98, 0.6) 0,
            rgba(5, 23, 43, 0.7) 15%,
            rgba(5, 23, 43, 0.7) 100%);

    .ant-modal-content {
        .ant-modal-close {
            display: inline-block;
            top: 0;
        }

        .ant-modal-header {
            background-color: transparent;
            border-bottom: 0 none;

            .ant-modal-title {
                text-align: center;
                color: #fff;
            }
        }
    }
}
@primary-color: #0ad0d0;