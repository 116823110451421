.energy-consumption-left-one {
  display: flex;
  flex: 1;
  flex-direction: column;
  color: #fff;
  height: 100%;
  // background-color: red;
  padding-bottom: 20rem;



  .boxItem {
    margin-bottom: 2vh;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    flex:1;
    height: 80rem;
    font-size: 14rem;
    align-items: center;
    color: #fff
  }

  .boxItem p {
    /* line */
    margin-bottom: 0;
    line-height: 2.1;
    font-size: 10rem;
    display: flex;
    flex-direction: row;
    span{
      display: flex;
      flex:1
    }
    &>span:nth-child(1){
      margin-left:20rem;
    }
  }

  .boxItem>div:nth-child(1) {
    margin-right: 22rem;
    width: 110rem;
    height: 80rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   
  }

  .boxItem>div:nth-child(2) {
    background: url('../../../../../static/images/convenient-access-eq-bg.png') center no-repeat;
    background-size: 100% 100%;
    padding: 0 24rem;
    width:280rem;
    height:100%;
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: center;
  }

  .boxItem:nth-child(1)>div:nth-child(1) {
    background: url('../../../../../static/images/convenientAccess-eq-3.png') center no-repeat;
    background-size: 100% 100%;

  }

  .boxItem:nth-child(2)>div:nth-child(1) {
    background: url('../../../../../static/images/convenientAccess-eq-4.png') center no-repeat;
    background-size: 100% 100%;
  }


  .boxItem>div:nth-child(1) div {
    margin-top: 12rem;
    height: 56rem;
    width: 56rem;
    /* background-color: #FFF799; */
  }

  .boxItem>div:nth-child(1) span {
    font-size: 14rem;
    margin-top: 10rem;
  }

  .boxItem:nth-child(1) {
    margin-top: 23rem;
  }
}
@primary-color: #0ad0d0;