.pageCard {
	width: 100%;
	height: 100%;
    overflow: hidden;
	position: relative;
	background: rgba(0, 160, 229, 0.3);
	background: linear-gradient(
		to right,
		rgba(0, 160, 229, 0.01) 0%,
		rgba(0, 160, 229, 0.25) 35%,
		rgba(0, 160, 229, 0.31) 50%,
		rgba(0, 160, 229, 0.25) 65%,
		rgba(0, 160, 229, 0.01) 100%
	);
}
.title {
	background-color: rgba(6, 231, 233, 0.38);
	width: 100%;
	height: 30rem;
	font-size: 16rem;
	color: rgb(255, 255, 255);
	line-height: 30rem;
	text-align: center;
	background: linear-gradient(
		to right,
		rgba(6, 231, 233, 0) 0%,
		rgba(6, 231, 233, 0.28) 30%,
		rgba(6, 231, 233, 0.38) 50%,
		rgba(6, 231, 233, 0.28) 70%,
		rgba(6, 231, 233, 0) 100%
	);
}
.cardContainer {
	position: relative;
	height: calc(100% - 30rem);
}
