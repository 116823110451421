
.total {
    position: absolute;
    right: 0;
    top: -29rem;
    right: 4rem;
    font-size: 16rem;
    color: #FFF799;
    line-height: 30rem;
}

.tableHeard {
    margin: 10rem 0rem;
    width: 450rem;
    display: flex;
}

.tableHeard span {
    display: flex;
    color: rgba(0, 160, 229, 1);
    font-size: 16rem;
    justify-content: center;
    margin-left: 10rem;
}

.tableHeard span:nth-child(1) {

    flex: 1;
    margin-left: 0rem;
}

.tableHeard span:nth-child(2) {
    flex: 1;
    justify-content: center;
    margin-left: -20rem
}

.tableHeard span:nth-child(3) {
    flex: 1.5;
}

.tableHeard span:nth-child(4) {
    flex: 1;
}

.table {
    font-size: 13rem;
    color: #fff;
    height: 100%;
}

.table>div {
    position: relative;
    height: 100%;
    overflow: hidden;
    margin-right: 20rem;
    margin-left: 20rem;
}

.longView {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row-reverse;
    position: relative;
}

.longView>div {
    height: auto;
    width: 118rem;
    margin-left:20rem ;
}
.longView>div .imgBox{
    width:  118rem;
    height: 139rem;
    display: flex;
    align-items: center;
}
.infoBox{
    position: relative;
    margin-top: 18rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 16rem;
}

.infoBox .infoName{
  width: 100%;
  text-align: center;
}
.infoBox .infoName::before{
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 7rem;
    height: 7rem;
    border-top: 2rem solid #fff;
    border-left: 2rem solid #fff;

}
.infoBox .infoName::after{
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    width: 7rem;
    height: 7rem;
    border-top: 2rem solid #fff;
    border-right: 2rem solid #fff;
}
.infoBox .infoTime{
    width: 100%;
    text-align: center;
  }
  .infoBox .infoTime::before{
    content: '';
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 7rem;
    height: 7rem;
    border-bottom: 2rem solid #fff;
    border-left: 2rem solid #fff;
}
  .infoBox .infoTime::after{
    content: '';
    display: inline-block;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 7rem;
    height: 7rem;
    border-bottom: 2rem solid #fff;
    border-right: 2rem solid #fff;
} 